<template>
  <div class="Loginblock constrain part mt-8 xl:mt-24">
    <div class="row-12">
      <div class="md:col-6 md:offset-3">
        <div class="login-container bg-white p-8 md:p-12 xl:mt-20">
          <h2 class="login-title text-center pb-12 mb-16">
            Login
          </h2>
          <template v-if="!$store.state.user">
            <form ref="loginForm" @submit.prevent="login">
              <div class="row-12">
                <div class="sm:col-8 sm:offset-2 lg:col-6 lg:offset-3 flex flex-col mb-8">
                  <label for="username">{{ payload.benutzername }}</label>
                  <input
                    id="username"
                    required
                    type="text"
                    name="username"
                  >
                </div>
                <div class="sm:col-8 sm:offset-2 lg:col-6 lg:offset-3 flex flex-col mb-8">
                  <label for="password">{{ payload.passwort }}</label>
                  <input
                    id="password"
                    required
                    type="password"
                    name="password"
                  >
                  <input type="hidden" name="next">
                </div>
                <div class="md:col-2 md:offset-5 mt-8 flex justify-center">
                  <button type="submit" class="util-btn uppercase">
                    Login
                  </button>
                </div>
              </div>
            </form>
            <div ref="messageContainer" class="response-text mt-12 text-center text-red" />
          </template>
          <template v-else>
            <h1>{{ payload.already }}</h1>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { checkLoggedIn } from '@/utils/loggedin';

export default {
  components: { },
  props: { payload: { type: Object, default: Object } },
  data() {
    return {
      isLoggedIn: false,
    };
  },
  computed: {
    locale() {
      return this.$store.state.router.locale;
    },
  },
  async mounted() {
    const response = await fetch(
      `${this.$store.state.router.base}/auth/isloggedin`,
      {
        method: 'GET',
        credentials: 'include',
      },
    );
    const json = await response.json();
  },
  methods: {
    async login() {
      const { loginForm } = this.$refs;
      const response = await fetch(
        `${this.$store.state.router.base}/auth/login`,
        {
          method: 'POST',
          body: new FormData(loginForm),
          credentials: 'include',
        },
      );
      const json = await response.json();

      if (json.type === 'error') {
        if (this.locale === 'de') {
          this.$refs.messageContainer.textContent = 'Benutzername oder Passwort falsch';
        } else if (this.locale === 'fr') {
          this.$refs.messageContainer.textContent = 'Nom d\'utilisateur ou mot de passe erroné';
        }
      }

      await checkLoggedIn(this.$store);
    },
  },
};
</script>

<style scoped lang="scss">
.Loginblock {
  @screen xl {
    min-height: 800px;
  }
}

.login-title {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 2px;
    width: px(75);
    transform: translateX(-50%);
    background-color: var(--color-light-blue);
  }
}
</style>
